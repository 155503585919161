<template>
  <div class="outer-box">
    <Echart
      :options="options"
      id="line-area"
      :height="height"
      :width="width"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {},
      width: "",
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
    height:{
      type: String
    }
  },
  created() {},
  watch: {
    cdata: {
      handler(newData) {
        if (newData == null) return;
        this.height = this.height==""?"200px":this.height;
        this.width = "100%";
        this.init(newData);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    init: function (newData) {
      this.options = {
        // backgroundColor: "#E6EBF8",
        backgroundColor: "transparent",
        color: ["#80FFA5", "#00DDFF", "#37A2FF", "#FF0087", "#FFBF00"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          textStyle: {
            color: "#E6EBF8",
          },
          data: [
           "温度","湿度"
          ],
        },
        dataZoom: [
          {
            type: "inside",
            xAxisIndex: 0,
            minSpan: 5,
          },
        ],
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: newData.xAxisData,
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: {
              //   show: false,
              //网格线
              lineStyle: {
                color: "#2A2C2A",
              },
            },
          },
        ],
        series: [
          {
            name: "湿度",
            type: "line",
            smooth: true,
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.5,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgb(128, 255, 165)",
                },
                {
                  offset: 1,
                  color: "rgb(1, 191, 236)",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
            data: newData.humData,
          },
          {
            name: "温度",
            type: "line",
            smooth: true,
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.5,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgb(55, 162, 255)",
                },
                {
                  offset: 1,
                  color: "rgb(116, 21, 219)",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
            data: newData.tempeData,
          },
        ],
      };
    },
  },
};
</script>
