<template>
  <div id="pre-box">
    <div>
      <div class="pre-chart bg-color-black">
        <div class="d-flex pt-2 pl-2">
          <span class="iconfont icontongji text-icon"></span>
          <div class="d-flex">
            <span class="fs-xl text mx-2">风向风力概况</span>
            <dv-decoration-3 class="dv-dec-3" />
          </div>
        </div>
        <!-- 风向风速统计图 -->
        <Echart
          :options="options"
          id="airseeding-analyze"
          :height="windHeight"
          :Width="windWidth"
        ></Echart>
      </div>
    </div>
  </div>
</template>

<script>
import Echart from "@/common/echart";
import {degreesConversion} from "../../utils/index"
export default {
  data() {
    return {
      windWidth: "100%",
      windHeight: "300px",
      directionMap: {},
      directionDict: [
        "西",
        "西南偏西",
        "西南",
        "西南偏南",
        "南",
        "东南偏南",
        "东南",
        "东南偏东",
        "东",
        "东北偏东",
        "东北",
        "东北偏北",
        "北",
        "西北偏北",
        "西北",
        "西北偏西",
      ],
      windDataEchart: [],
      dims: {
        time: 0,
        windSpeed: 1,
        R: 2,
      },
      arrowSize: 18, //风向箭头大小
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    windData: Object,
  },
  mounted() {},
  watch: {
    windData: {
      handler(newData) {
        if (newData == null) return;
        this.init(newData);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    init(newData) {
      let length = newData.xAxisData.length;
      this.windDataEchart = [];
      for (let i = 0; i < length; i++) {
        this.windDataEchart.push([
          newData.xAxisData[i],
          // Number(newData.yAxisWinSpeed[i] * 1.94384449).toFixed(2),
          newData.yAxisWinSpeed[i],
          degreesConversion(Number(newData.yAxisWinDire[i])),
        ]);
      }
      let _that = this;
      this.directionDict.forEach(function (name, index) {
        _that.directionMap[name] = (Math.PI / 8) * index;
      });
      this.options = {
        title: {
          text: "",
          subtext: "",
          left: "center",
        },
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            return [
              // _that.$echarts.time.format(
              //   "yyyy-MM-dd",
              //   params[0].value[_that.dims.time]
              // ) +
              //   " " +
              //   _that.$echarts.time.format(
              //     "hh:mm",
              //     params[0].value[_that.dims.time]
              //   ),
              params[0].value[_that.dims.time],
              "风速：" + params[0].value[_that.dims.windSpeed],
              "风向：" + params[0].value[_that.dims.R],
            ].join("<br>");
          },
        },
        grid: {
          //   top: 160,
          //   bottom: 125,
        },
        xAxis: {
          type: "time",
          maxInterval: 3600 * 1000 * 24,
          splitLine: {
            lineStyle: {
              color: "#ddd",
            },
          },
        },
        yAxis: [
          {
            name: "风速（m/s）",
            nameLocation: "middle",
            nameGap: 35,
            axisLine: {
              lineStyle: {
                color: "#99D9EA",
              },
            },
            splitLine: {
              lineStyle: {
                color: "#2A2C2A",
              },
            },
          },
          {
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: { show: false },
            splitLine: { show: false },
          },
        ],
        visualMap: {
          type: "piecewise",
          //   show: false,
          orient: "horizontal",
          left: "center",
          top: 10,
          pieces: [
            {
              gte: 17.2,
              color: "#D33C3E",
              label: "大风（>=17.2m/s）",
            },
            {
              gte: 17.1,
              lt: 5.5,
              color: "#f4e9a3",
              label: "中风（5.5  ~ 17.1m/s）",
            },
            {
              lt: 5.5,
              color: "#18BF12",
              label: "微风（小于5.5m/s）",
            },
          ],
          seriesIndex: 1,
          dimension: 1, //指定用数据的『哪个维度』，映射到视觉元素上
          textStyle: {
            color: "#99D9EA",
          },
        },
        dataZoom: [
          {
            start: 0,
            end: 25,
            minSpan: 5,
          },
          {
            type: "inside",
            xAxisIndex: 0,
            minSpan: 5,
          },
        ],
        series: [
          {
            type: "line",
            yAxisIndex: 1,
            showSymbol: false,
            emphasis: {
              scale: false,
            },
            symbolSize: 10,
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                global: false,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(88,160,253,1)",
                  },
                  {
                    offset: 0.5,
                    color: "rgba(88,160,253,0.7)",
                  },
                  {
                    offset: 1,
                    color: "rgba(88,160,253,0)",
                  },
                ],
              },
            },
            lineStyle: {
              color: "rgba(88,160,253,1)",
            },
            itemStyle: {
              color: "rgba(88,160,253,1)",
            },
            encode: {
              x: this.dims.time,
              y: this.dims.windSpeed,
            },
            data: this.windDataEchart,
            z: 2,
          },
          {
            type: "custom",
            renderItem: this.renderArrow,
            encode: {
              x: this.dims.time,
              y: this.dims.windSpeed,
            },
            data: this.windDataEchart,
            z: 10,
          },
        ],
      };
    },
    // 生成风向箭头
    renderArrow: function (param, api) {
      const point = api.coord([
        api.value(this.dims.time),
        api.value(this.dims.windSpeed),
      ]);
      return {
        type: "path",
        shape: {
          pathData: "M31 16l-15-15v9h-26v12h26v9z",
          x: -this.arrowSize / 2,
          y: -this.arrowSize / 2,
          width: this.arrowSize,
          height: this.arrowSize,
        },
        rotation: this.directionMap[api.value(this.dims.R)],
        position: point,
        style: api.style({
          stroke: "#555",
          lineWidth: 1,
        }),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
$box-width: 100%;
$box-height: 350px;

#pre-box {
  padding: 0 16px;
  height: $box-height;
  width: $box-width;
  border-radius: 10px;
  .bg-color-black {
    // height: $box-height - 30px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
    font-size: 14px;
  }
  .dv-dec-3 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }
}
</style>
