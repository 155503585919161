<template>
  <div id="pre-box">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <span class="iconfont icontongji text-icon"></span>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">24小时土壤温湿度</span>
          <dv-decoration-3 class="dv-dec-3" />
        </div>
      </div>

      <div class="pre-chart">
        <!-- 折线面积图表 -->
        <e-brokenline-area-map :cdata="echartUpData" height="295px"></e-brokenline-area-map>
      </div>
    </div>
  </div>
</template>

<script>
import EBrokenlineAreaMap from "@/components/echart/brokenlineAreaMap";
export default {
  data() {
    return {
      brokenLineWidth: "100%",
      brokenLineHeight: "300px",
      echartUpData:{}
    };
  },
  components: {
    EBrokenlineAreaMap,
  },
  props: {
    echartData: Object,
  },
  watch: {
    echartData: {
      handler(newData) {
        if(newData==null) return 
        this.echartUpData = newData
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
$box-width: 100%;
$box-height: 336px;

#pre-box {
  padding: 0 16px;
  height: $box-height;
  width: $box-width;
  border-radius: 10px;
  .bg-color-black {
    // height: $box-height - 30px;
    height: $box-height;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
    font-size: 14px;
  }
  .dv-dec-3 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }
  .p-me-box {
    justify-content: space-around;
    padding: 10px 0;
  }

  .duration-switching {
    span {
      font-size: 16px;
      line-height: 1.5;
      padding: 5px 20px;
      background: #393e47;
      cursor: pointer;
    }
    span.check {
      color: #90e1f4;
    }
  }
}
</style>
