<template>
  <div id="pre-box">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <span class="iconfont icontongji text-icon"></span>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">降水量</span>
          <dv-decoration-3 class="dv-dec-3" />
        </div>
      </div>
      <div class="d-flex p-me-box">
        <zk-meteorological-index
          :meImg="require('@/assets/visual/rain1.png')"
          meTitle="站点降水量"
          :meValue="sitePre"
          :meUnit="unit"
        ></zk-meteorological-index>
        <zk-meteorological-index
          :meImg="require('@/assets/visual/rain2.png')"
          meTitle="最大降水量"
          :meValue="maxPre"
          :meUnit="hisUnit"
        ></zk-meteorological-index>
        <zk-meteorological-index
          :meImg="require('@/assets/visual/rain3.png')"
          meTitle="最小降水量"
          :meValue="minPre"
          :meUnit="hisUnit"
        ></zk-meteorological-index>
      </div>

      <div class="pre-chart">
        <div>
          <div class="duration-switching">
            <span :class="isWeekChecked ? 'check' : ''" @click="changeWeekChart"
              >一周降水量</span
            >
            <span
              :class="isWeekChecked ? '' : 'check'"
              @click="changeMonthChart"
              >月降水量</span
            >
          </div>
        </div>
        <e-precipitation :cdata="echartData"></e-precipitation>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getPreWeekData, getPreMonthData } from "@/api/sensorData";
import EPrecipitation from "@/components/echart/precipitation";
import ZkMeteorologicalIndex from "@/components/meteorologicalIndexStatistics";
export default {
  data() {
    return {
      precipitationWeekData: null,
      precipitationMonthData: null,
      echartData: null,
      isWeekChecked: true,
      sitePre: null,
      minPre: null,
      maxPre: null,
      unit: null,
      hisUnit: null,
    };
  },
  computed: {
    ...mapState(['deviceId'])
  },
  components: {
    EPrecipitation,
    ZkMeteorologicalIndex,
  },
  props: ["precipitationData"],
  watch: {
    precipitationData: {
      handler(newData) {
        if (newData == null) return;
        const { hisUnit } = newData;
        this.sitePre = newData.sitePre;
        this.minPre = newData.minPre;
        this.maxPre = newData.maxPre;
        this.unit = newData.unit;
        this.hisUnit = hisUnit;
        this.precipitationWeekData = newData.weekData;
        this.echartData = newData.weekData;
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {},
  methods: {
    changeWeekChart: function () {
      this.isWeekChecked = true;
      let formdata = new FormData();
      formdata.append("iotId",this.deviceId);
      // 周降水量查询
      getPreWeekData(formdata).then((res) => {
        const {
          preData: { weekData },
        } = res.data.data;
        this.echartData = weekData;
        let yAxisData = this.echartData.yAxisData;
        this.minPre = Math.min(...yAxisData).toFixed(2);
        this.maxPre = Math.max(...yAxisData).toFixed(2);
      });
    },
    changeMonthChart: function () {
      this.isWeekChecked = false;
      // 月降雨量查询
      console.log("month dev:"+this.deviceId);
      let formdata = new FormData();
      formdata.append("iotId",this.deviceId);
      getPreMonthData(formdata).then((res) => {
        this.echartData = res.data.data.monthData;
        let yAxisData = this.echartData.yAxisData;
        this.minPre = Math.min(...yAxisData).toFixed(2);
        this.maxPre = Math.max(...yAxisData).toFixed(2);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$box-width: 100%;
$box-height: 340px;

#pre-box {
  padding: 0 16px;
  height: $box-height;
  width: $box-width;
  border-radius: 10px;
  .bg-color-black {
    height: $box-height;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
    font-size: 14px;
  }
  .dv-dec-3 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }
  .p-me-box {
    justify-content: space-around;
    padding: 10px 0;
  }

  .duration-switching {
    span {
      font-size: 16px;
      line-height: 1.5;
      padding: 5px 20px;
      background: #393e47;
      cursor: pointer;
    }
    span.check {
      color: #90e1f4;
    }
  }

  .pre-chart {
    margin-top: 15px;
  }
}
</style>
