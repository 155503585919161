import request from "../axios/request";

export const getDataByMapId = function(data){
    return request.post('/iot/sensordatav/getDataByMapId',data);
}

export const getRealTimeData = function(data){
    return request.post('/iot/sensordatav/getRealTimeData',data);
}

export const get24Hours = function(data){
    return request.post('/iot/sensordatav/get24Hours',data);
}

export const getPreWeekData = function(iotid){
    return request.post('/iot/sensordatav/getPreWeekData',iotid);
}

export const getPreMonthData = function(iotid){
    return request.post('/iot/sensordatav/getPreMonthData',iotid);
}

export const getPreExtremumData = function(data){
    return request.post('/iot/sensordatav/getPreExtremumData',data);
}